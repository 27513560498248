@import '@assets/styles/abstracts';

.RegisterForm {
  .Error {
    color: color(error);
    margin-bottom: px(2);
  }

  .Message {
    color: color(success);
    margin-bottom: px(2);
  }

  .Checkbox {
    display: inline-flex;
  }
}
