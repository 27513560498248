@import '@assets/styles/abstracts';

.CookieDialog {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  max-height: 100vh;

  padding-top: px(3);
  padding-bottom: px(3);
  overflow-y: auto;

  background: #fff;
  border-top: solid 2px color(primary);
  box-shadow: 0 -8px 12px 0 rgba(212, 212, 212, 0.2);

  .DialogTitle {
    display: flex;
    align-items: center;
    margin-bottom: px(2);

    font-size: $font-size-h4;

    svg {
      width: 24px;
      margin-right: px(1.5);

      color: color(primary);
    }
  }

  .DialogContent {
    margin-bottom: px(2);

    font-weight: 400;
    font-size: 14px;

    @include media-breakpoint-down(sm) {
      margin-bottom: px(1);

      font-size: 10px;
      line-height: 12px;
    }
  }

  .DialogCookies {
    height: 0;
    margin: 0;
    min-height: 0;

    overflow: hidden;

    @include transition(all, md);

    &.CookiesVisible {
      min-height: 100px;
      height: auto;
      margin: px(3) 0;
    }
  }

  .DialogButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @include media-breakpoint-down(sm) {
      flex-direction: column;

      .DialogButton {
        width: 100%;
        margin-bottom: px(2);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding-top: px(0);
    padding-bottom: px(0);
  }
}

.Cookie {
  margin-top: px(2.5);
  overflow: hidden;

  .CookieHeader {
    display: flex;
    margin-bottom: px(1);
  }

  .CookieTitle {
    display: flex;
    align-items: center;
    padding-left: px(3);

    cursor: pointer;

    user-select: none;

    svg {
      width: 10px;
      margin-left: px(1);
    }
  }

  .CookieCheckbox {
    position: relative;

    display: block;
    margin-bottom: 0;

    cursor: pointer;

    user-select: none;

    input {
      position: absolute;

      width: 0;
      height: 0;

      cursor: pointer;
      opacity: 0;

      &:checked ~ label {
        background-color: color(primary);

        svg {
          opacity: 1;
        }
      }

      &:disabled ~ label {
        opacity: 0.3;
      }
    }

    label {
      padding: 2px;

      background-color: #fff;
      border: solid 2px color(primary);

      border-radius: 2px;
      cursor: pointer;

      svg {
        color: #fff;

        opacity: 0;
      }
    }
  }

  .CookieSummary {
    max-height: 0;

    font-size: 14px;

    @include transition(all, md);
  }

  &.CookieActive {
    .CookieTitle {
      svg {
        transform: rotate(180deg);
      }
    }

    .CookieSummary {
      max-height: 150px;
    }
  }
}
