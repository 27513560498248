@use "sass:math";

@mixin box-shadow($value: 1) {
  box-shadow: shadow($value);
}

@mixin transition($attributes, $value: md) {
  transition: $attributes map-get($transitions, $value);
}

@mixin max-lines($value: 1, $line-height: $line-height-base) {
  display: -webkit-box;
  -webkit-line-clamp: $value;
  -webkit-box-orient: vertical;

  height: calc(#{$line-height} * 1em * #{$value});
  overflow: hidden;
}

@mixin aspect($width: 100%, $ratio: '16:9') {
  $ratios: (
    '1:1': 100%,
    '16:9': 56.25%,
    '16:10': 62.5%,
    '3:2': 66.66%,
    '4:3': 75%,
    'tip': 128%,
    'seperator': 6.22%,
    '3:4': 133.33%,
    '2:3': 150%,
    '10:16': 160%,
    '9:16': 177.78%,
  );
  position: relative;

  width: $width;
  height: 0;
  padding-top: map-get($ratios, $ratio) * math.div($width, 100%);
  overflow: hidden;

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin max-col-width($col, $bp: xl) {
  max-width: calc(#{map-get($container-max-widths, $bp)} / 12 * $col);
}

@mixin touch-window-left() {
  left: calc(#{distance-to-window-left(xxl)} * -1);

  @include media-breakpoint-down(xl) {
    left: calc(#{distance-to-window-left(xl)} * -1);
  }

  @include media-breakpoint-down(lg) {
    left: calc(#{distance-to-window-left(lg)} * -1);
  }

  @include media-breakpoint-down(md) {
    left: calc(#{distance-to-window-left(md)} * -1);
  }

  @include media-breakpoint-down(sm) {
    left: calc(#{distance-to-window-left(sm)} * -1);
  }

  @include media-breakpoint-down(xs) {
    left: calc(#{distance-to-window-left(xs)} * -1);
  }
}

@mixin add-window-distance-width() {
  width: calc(100% + #{distance-to-window-left(xxl)});

  @include media-breakpoint-down(xl) {
    width: calc(100% + #{distance-to-window-left(xl)});
  }

  @include media-breakpoint-down(lg) {
    width: calc(100% + #{distance-to-window-left(lg)});
  }

  @include media-breakpoint-down(md) {
    width: calc(100% + #{distance-to-window-left(md)});
  }

  @include media-breakpoint-down(sm) {
    width: calc(100% + #{distance-to-window-left(sm)});
  }

  @include media-breakpoint-down(xs) {
    width: calc(100% + #{distance-to-window-left(xs)});
  }
}
