@import '@assets/styles/abstracts';

.LoginPage {
  .HeaderContainer {
    margin-bottom: px(6);
  }

  .SocialLogins {
    margin: px(4) 0;

    .FacebookLogin {
      margin-right: px(2);
      margin-bottom: px(2);
    }
  }
}
