// 1. Settings
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$theme-colors: (
  primary: (
    default: #ff7546,
    lightest: #ff75460d,
    lighter: #fda98d,
    light: #ff9a78,
  ),
  secondary: (
    default: #0087cd,
    lightest: #cdeeff,
    dark: #005079,
  ),
  yellow: (
    default: #f1c41f,
    light: #fff9e3,
  ),
  black: (
    default: #000000,
  ),
  white: (
    default: #ffffff,
    100: #d7e2f3,
    200: #e9f0fd,
    300: #f2f8ff,
    400: #f7f7fd,
  ),
  support: (
    default: #8d9eb8,
    lightest: #fafbff,
    lighter: #ececec,
    light: #b0b1b5,
    medium: #96a0a6,
    dark: #4f5457,
    darker: #3c4962,
    darkest: #001a28,
  ),
  success: (
    default: #49b85b,
  ),
  warning: (
    default: #ffcc00,
    dark: #e4b80b,
  ),
  error: (
    default: #ff6b57,
    strong: #f73f27,
    dark: #d12d17,
  ),
  transparent: (
    default: rgba(0, 0, 0, 0),
    primary: rgba(255, 117, 70, 0),
    secondary: rgba(0, 135, 205, 0),
  ),
  grey: (
    default: #dee6e8,
  ),
  background: (
    default: #fafcfc,
  ),
  social: (
    facebook: #4267b2,
    twitter: #1da1f2,
    instagram: #fd1d1d,
    google: #4688f1,
  ),
);

$gradients: (
  primary: linear-gradient(to bottom, color(primary), color(white)),
  secondary: linear-gradient(to bottom, color(secondary), color(white)),
  tertiary: linear-gradient(to bottom, color(white, 100), color(white)),
  dark: linear-gradient(to bottom, color(black), color(white)),
  light: linear-gradient(to bottom, color(white), color(white)),
);

$shadows: (
  sm: (
    0 2px 4px 0 rgba(0, 0, 0, 0.1),
  ),
  md: (
    0 11px 18px 0 rgba(0, 0, 0, 0.08),
  ),
  lg: (
    0 11px 18px 0 rgba(0, 0, 0, 0.25),
  ),
  primary: (
    0 11px 18px 0 rgba(255, 117, 70, 0.5),
  ),
  secondary: (
    0 11px 18px 0 rgba(0, 135, 205, 0.35),
  ),
);

$easing: cubic-bezier(0.2, 0.4, 0, 1);

$transitions: (
  xs: 0.1s $easing,
  sm: 0.15s $easing,
  md: 0.2s $easing,
  lg: 0.3s $easing,
  xl: 0.5s $easing,
);

$spacings: (
  none: 0,
  xs: px(1),
  sm: px(2),
  md: px(4),
  lg: px(6),
  xl: px(8),
  xxl: px(10),
);

$box-shadow: 0 4px 6px 1px rgba(0, 0, 0, 0.06), 0 0 1px 1px rgba(0, 0, 0, 0.02);

// 2. Options
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$body-color: #2e384d;
$body-background: #ffffff;

$border-radius: 0;
$default-margin: px(3);

// 2.1 Typography
$font-family-base: 'Libre Franklin', sans-serif;
$font-family-alt: 'Nunito Sans', sans-serif;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 900;

$font-size-base: 16px;
$font-weight-base: $font-weight-light;
$line-height-base: 1.8;
$font-family-base: $font-family-base;

$font-size-xxxs: rem(8px);
$font-size-xxs: rem(10px);
$font-size-xs: rem(12px);
$font-size-sm: rem(14px);
$font-size-md: rem(16px);
$font-size-lg: rem(18px);
$font-size-xl: rem(20px);
$font-size-xxl: rem(28px);
$font-size-xxxl: rem(48px);

$headings-font-family: $font-family-base;
$headings-color: color(support, darkest);
$headings-font-weight: $font-weight-semibold;
$headings-line-height: 1.22;
$headings-margin-bottom: rem(20px);

$font-size-h1: rem(56px);
$font-size-h2: rem(36px);
$font-size-h3: rem(24px);
$font-size-h4: rem(20px);
$font-size-h5: rem(18px);
$font-size-h6: rem(16px);

$paragraph-margin: 26px;
$paragraph-font-weight: $font-weight-light;
$paragraph-lead-font-size: $font-size-xl;
$paragraph-lead-font-weight: $font-weight-semibold;
$paragraph-lead-margin-bottom: $paragraph-margin * 1.2;

$link-color: color(secondary);
$link-decoration: none;
$link-hover-color: darken($link-color, 20%);
$link-hover-decoration: none;

$hr-color: color(support, light);
$hr-height: spacing(lg);

$list-margin: $default-margin;
$list-item-margin: px(0.5);

$blockquote-margin: px(3) 0 px(7) 0;
$blockquote-padding: px(4) px(3);

// 2.2 Buttons
$button-colors: (
  primary: color(primary),
  secondary: color(secondary),
);

$button-font-weight: $font-weight-regular;
$button-font-color: white;
$button-border-radius: $border-radius;

$button-sm-padding: px(0.5) px(1.5);
$button-padding: 7px 15px 4px 15px;
$button-lg-padding: px(1.5) px(3);

$button-sm-font-size: $font-size-sm;
$button-font-size: $font-size-sm;
$button-lg-font-size: $font-size-xl;

// 2.3 Forms
$input-font-size: $font-size-md;
$input-label-font-size: $font-size-base;
$input-background-color: color(support, lightest);
$input-active-color: color(primary, 300);
$input-radius: $border-radius;
$input-border-color: color(support, light);
$input-invalid-color: color(error);
$input-placeholder-color: color(primary);
$input-radio-checkbox: color(primary);

// 2.4 Images
$image-margin-bottom: $default-margin;

// 3. Layouts
$layout-vertical-padding: px(8);
$layout-horizontal-padding: 0;
//
// Define some settings for custom components, such as the header
// FAQ, and other components.

// 3.1 navbar

$navbar-z-index: 999;
$navbar-mobile-breakpoint: md;
$navbar-height: 76px;
$navbar-mobile-height: 64px;
