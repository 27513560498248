@import '@assets/styles/abstracts';

.GoogleLoginButton {
  display: inline-block;

  padding: px(1) px(2);
  color: color(white) !important;
  background-color: color(social, google);

  &:hover {
    opacity: 0.66;
  }

  svg {
    margin-right: px(2);
  }
}
