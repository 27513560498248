@import '@assets/styles/abstracts';

.InputGroup {
  .Input {
    position: relative;
    background-color: color(white);

    @include box-shadow(sm);

    &::after {
      content: ' ';

      position: absolute;
      bottom: 0;
      left: 0;

      height: 2px;
      width: 0;
      background-color: color(primary);

      @include transition(all, sm);
    }

    input {
      display: block;
      box-sizing: border-box;
      border: none;
      font-weight: $font-weight-semibold;

      font-size: $font-size-sm;
      color: color(support, darkest);

      padding: 20px 18px 15px;
      width: 100%;

      &:focus {
        outline: none;
      }

      &:focus {
        ~ label {
          top: 12px;
          font-size: $font-size-xxs;
        }
      }

      &:disabled {
        background-color: color(support, lighter);
        color: color(support, medium);

        & ~ label {
          color: color(support, medium);
        }
      }
    }

    label {
      position: absolute;
      pointer-events: none;

      color: color(support, darkest);
      font-size: $font-size-sm;
      font-weight: normal;

      left: 18px;
      top: 50%;

      transform: translateY(-50%);

      @include transition(all, md);
    }

    &.valid {
      &::after {
        width: 100%;
      }

      label {
        top: 12px;
        font-size: $font-size-xxs;
      }
    }
  }

  .Error {
    margin-top: 5px;
    color: color(primary);
  }
}
