@import '@assets/styles/abstracts';

.LoginForm {
  .FormFooter {
    display: flex;
    padding-top: px(1);
    align-items: flex-start;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  .Error {
    color: color(error);
  }
}
